<template>
    <div id="footer" class="py-2">
     <b-navbar toggleable='md'  class="mx-3" type="light">
      <b-navbar-brand >WebANDeRsEn</b-navbar-brand>
    <b-collapse  is-nav>
      <b-navbar-nav  toggleable='lg'>
        <b-row class="">
          <b-col cols="4" v-for="(text,index) in listText" :key="index"  >
                <b-nav-item :href="listUrl[index]">{{text}}</b-nav-item>
          </b-col>
        </b-row>

      </b-navbar-nav>
    </b-collapse>
    <div>
    ©{{ new Date().getFullYear() }} <font><strong>Пяк Андрей</strong></font>
    </div>
  </b-navbar>
       
    </div>
</template>
<script>
export default {
    props:['listText','listUrl']
}
</script>
<style>
#footer
{
     background-color:#d0d0d0;
}
</style>
