<template>

    <div id="bubbles" class="bubbles">
        
        <b-container class="my-4  text-dark" fluid="xl">
            <b-row class="my-2">
            <b-col cols="12" class=" mx-auto " lg="8" xl="7">
                    <b-jumbotron container-fluid="0" class="my-4 text-center" header="Кто я" >
                        <p>Доброго времени суток. Меня зовут Пяк Андрей, мне 22 года и я начинающий Full-stack разработчик. Проживаю в Узбекистане.</p>
                        <p>Чтобы совместить приятное с полезным, я предлагаю вам заказать у меня сайт, телеграм-бота или лэндинг почти за бесплатно. Ваш сайт мне нужен будет для портфолио, от чего ваш сайт только выиграет, ведь мне нужно крутое портфолио. </p>
                        <p>Напишите мне в телеграм, чтобы обсудить ваш проект: </p>   <b-button :href="Tg" variant="info">Telegram</b-button>
                    </b-jumbotron>
                </b-col>
            </b-row>
            <b-row class="my-2">
              
            </b-row>
        </b-container>
     
        <div class="bubble" v-for="(n, i) in 10" :key="i" ></div> 
      
    </div>
 
</template>
<script>
export default {
 props:["Tg"]
        
}
</script>

<style scoped>
.bubbles {

  position:relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
.bubble {
 z-index: -3;
  position: absolute;
  left: var(--bubble-left-offset);
  bottom: -100%;
  display: block;
  width: var(--bubble-radius);
  height: var(--bubble-radius);
  border-radius: 50%;
  animation: float-up var(--bubble-float-duration) var(--bubble-float-delay) ease-in infinite;
}
.bubble::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d9dcf5;
  border-radius: inherit;
  animation: var(--bubble-sway-type) var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite;
}
.bubble:nth-child(0) {
  --bubble-left-offset: 30vw;
  --bubble-radius: 4vw;
  --bubble-float-duration: 11s;
  --bubble-sway-duration: 5s;
  --bubble-float-delay: 4s;
  --bubble-sway-delay: 2s;
  --bubble-sway-type: sway-left-to-right;
}
.bubble:nth-child(1) {
  --bubble-left-offset: 90vw;
  --bubble-radius: 5vw;
  --bubble-float-duration: 7s;
  --bubble-sway-duration: 4s;
  --bubble-float-delay: 4s;
  --bubble-sway-delay: 4s;
  --bubble-sway-type: sway-right-to-left;
}
.bubble:nth-child(2) {
  --bubble-left-offset: 16vw;
  --bubble-radius: 2vw;
  --bubble-float-duration: 10s;
  --bubble-sway-duration: 6s;
  --bubble-float-delay: 0s;
  --bubble-sway-delay: 1s;
  --bubble-sway-type: sway-right-to-left;
}
.bubble:nth-child(3) {
  --bubble-left-offset: 53vw;
  --bubble-radius: 7vw;
  --bubble-float-duration: 8s;
  --bubble-sway-duration: 5s;
  --bubble-float-delay: 0s;
  --bubble-sway-delay: 3s;
  --bubble-sway-type: sway-left-to-right;
}
.bubble:nth-child(4) {
  --bubble-left-offset: 63vw;
  --bubble-radius: 4vw;
  --bubble-float-duration: 10s;
  --bubble-sway-duration: 4s;
  --bubble-float-delay: 0s;
  --bubble-sway-delay: 3s;
  --bubble-sway-type: sway-left-to-right;
}
.bubble:nth-child(5) {
  --bubble-left-offset: 13vw;
  --bubble-radius: 3vw;
  --bubble-float-duration: 11s;
  --bubble-sway-duration: 6s;
  --bubble-float-delay: 0s;
  --bubble-sway-delay: 3s;
  --bubble-sway-type: sway-right-to-left;
}
.bubble:nth-child(6) {
  --bubble-left-offset: 24vw;
  --bubble-radius: 10vw;
  --bubble-float-duration: 10s;
  --bubble-sway-duration: 4s;
  --bubble-float-delay: 2s;
  --bubble-sway-delay: 4s;
  --bubble-sway-type: sway-right-to-left;
}
.bubble:nth-child(7) {
  --bubble-left-offset: 34vw;
  --bubble-radius: 8vw;
  --bubble-float-duration: 6s;
  --bubble-sway-duration: 4s;
  --bubble-float-delay: 3s;
  --bubble-sway-delay: 3s;
  --bubble-sway-type: sway-left-to-right;
}
.bubble:nth-child(8) {
  --bubble-left-offset: 41vw;
  --bubble-radius: 9vw;
  --bubble-float-duration: 11s;
  --bubble-sway-duration: 5s;
  --bubble-float-delay: 0s;
  --bubble-sway-delay: 1s;
  --bubble-sway-type: sway-right-to-left;
}
.bubble:nth-child(9) {
  --bubble-left-offset: 27vw;
  --bubble-radius: 6vw;
  --bubble-float-duration: 7s;
  --bubble-sway-duration: 5s;
  --bubble-float-delay: 2s;
  --bubble-sway-delay: 4s;
  --bubble-sway-type: sway-right-to-left;
}
.bubble:nth-child(10) {
  --bubble-left-offset: 11vw;
  --bubble-radius: 1vw;
  --bubble-float-duration: 8s;
  --bubble-sway-duration: 5s;
  --bubble-float-delay: 0s;
  --bubble-sway-delay: 1s;
  --bubble-sway-type: sway-left-to-right;
}

@keyframes float-up {
  to {
    transform: translateY(-175vh);
  }
}
@keyframes sway-left-to-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes sway-right-to-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>