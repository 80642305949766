<template>
<div class="w-full" id="banner">
 <div class="fonbannerGray"></div>
<div class="Header-background"></div>
  <b-container class="my-4  text-white" fluid="xl">
  <b-row>

    <b-col  cols="12" class="d-flex align-items-center" lg="6" xl="6">
      <b-jumbotron :header="BannerT[0]" :lead="BannerT[1]">
        <p>{{BannerT[2]}}</p>
        <b-button variant="primary" :href="Tg" >Заказать проект</b-button>
      </b-jumbotron>
    </b-col>
    <b-col class="d-none d-lg-block" cols="12" lg="6" xl="6">
      <b-img src="img/banner3.png" fluid alt="Responsive image"></b-img>
    </b-col>
  </b-row>
</b-container>

</div>

</template>
<script>
export default {
        props:['BannerT','Tg']
        
}
</script>
<style scoped>
.fonbannerGray
{
  background: #d9dcf5; 
  position:absolute;top:0;left:0;width:100%;height:100%;z-index:-2;
}
#banner{position:relative; width:100% ;  padding:4em 0 ;}
.Header-background{position:absolute;top:0;left:0;width:100%;height:98%;background:linear-gradient(19deg, rgb(67, 67, 143), rgb(150, 217, 238));transform-origin:0px 0px;transform:skewY(-10deg);overflow:hidden;z-index:-1;}
.Header-background::before,.Header-background::after{display:block;position:absolute;content:'';width:80%;height:33.333333333333336%;opacity:0.3;filter:blur(15px);}
.Header-background::after{background:rgb(67, 67, 143);bottom:0;}
@media (max-width: 992px){
  .Header-background{transform:skewY(0);}
}
</style>