<template>
  <div class="w-full ">
    <div>
        <b-navbar  class="w-full " type="dark" variant="secondary">
         
          <b-navbar-nav>
              <b-button v-b-toggle.sidebar-1>Меню</b-button> 
          </b-navbar-nav>
            
          <b-navbar-nav  style="position:absolute; right:0;" > <img width="40"   src="img/logo.gif"/></b-navbar-nav>
       
        </b-navbar>
            
    </div>

    <b-sidebar bg-variant='light'  backdrop no-enforce-focus id="sidebar-1" text-variant='Вторичная ссылка' title="Меню" shadow>
      <div class="px-3 py-2">
         <nav   class="mb-3">
            <b-navbar-nav   vertical>
              <b-nav-item link-classes='text-dark'  variant="dark"  class="btn text-secondary" v-for="(text,index) in listText" :key="index"  :href="listUrl[index]">{{text}}</b-nav-item>
             
            </b-navbar-nav>
          </nav>
     
      </div>
    </b-sidebar>
               
            
             
  </div>
</template>
<script>
export default {
    props:['listText','listUrl']
}
</script>
