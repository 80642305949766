<template>
  <div id="app" >
 
      <Header  :listUrl="listMenuUrl" :listText="listMenuText"></Header>
      <Banner :Tg="UrlTg" :BannerT="BanText"></Banner>  
      <aboutMe :Tg="UrlTg"></aboutMe>
      <Mycarousel :listImg='listImg'></Mycarousel>
      <app-header></app-header>
      <Footer :listUrl="listMenuUrl" :listText="listMenuText"></Footer>
    
  </div>
</template>
<script>
import Header from './components/Header.vue'
import Banner from './components/Banner.vue'
import aboutMe from './components/aboutMe.vue'
import Mycarousel from './components/myCarousel.vue'
import Footer from './components/footer.vue'
import  'bootstrap/dist/css/bootstrap.css' 
import  'bootstrap-vue/dist/bootstrap-vue.css'
  export default {
    name:'App',
      data()
    {
      return {
        listImg:[
          {id:0,text:'PHP — скриптовый язык общего назначения, интенсивно применяемый для разработки веб-приложений. В настоящее время поддерживается подавляющим большинством хостинг-провайдеров и является одним из лидеров среди языков, применяющихся для создания динамических веб-сайтов.',h1:'Язык программирования PHP', img:'php.png'},
          {id:1,text:'MySQL является решением для малых и средних приложений. Обычно MySQL используется в качестве сервера, к которому обращаются локальные или удалённые клиенты, однако в дистрибутив входит библиотека внутреннего сервера, позволяющая включать MySQL в автономные программы.',h1:'Cистема управления базами данных MySQL', img:'mysql.png'},
          {id:2,text:'PostgreSQL - самая продвинутая в мире база данных с открытым исходным кодом и четвертая по популярности база данных. '+'Благодаря своей расширяемости PostgreSQL предлагает широкий спектр встроенных  типов данных PostgreSQL',h1:'Cистема управления базами данных PostgreSQL', img:'postgre.png'},
          {id:3,text:'JavaScript-фреймворк с открытым исходным кодом для создания пользовательских интерфейсов. Легко интегрируется в проекты с использованием других JavaScript-библиотек. Может функционировать как веб-фреймворк для разработки web-приложений в реактивном стиле.',h1:'Vue.js', img:'vue.png'},
          {id:4,text:'Формальный язык описания внешнего вида документа (веб-страницы), написанного с использованием языка разметки (чаще всего HTML или XHTML). Также может применяться к любым XML-документам, например, к SVG или XUL.',h1:'Язык иерархических правил CSS', img:'css.png'},
          {id:5,text:'Cтандартизированный язык разметки документов для просмотра веб-страниц в браузере. Веб-браузеры получают HTML документ от сервера по протоколам HTTP/HTTPS или открывают с локального диска, далее интерпретируют код в интерфейс, который будет отображаться на экране монитора.',h1:'Язык гипертекстовой разметки HTML', img:'html.png'},
          {id:6,text:'Мультипарадигменный язык программирования. Поддерживает объектно-ориентированный, императивный и функциональный стили. Является реализацией спецификации ECMAScript. JavaScript обычно используется как встраиваемый язык для программного доступа к объектам приложений.',h1:'Язык программирования JavaScript', img:'js.png'},
          {id:7,text:'Набор функций JavaScript, фокусирующийся на взаимодействии JavaScript и HTML. Библиотека jQuery помогает легко получать доступ к любому элементу DOM, обращаться к атрибутам и содержимому элементов DOM, манипулировать ими. Также библиотека jQuery предоставляет удобный API для работы с AJAX.',h1:'Библиотека jQuery', img:'jq.png'},
          {id:8,text:'Свободный набор инструментов для создания сайтов и веб-приложений. Включает в себя HTML- и CSS-шаблоны оформления для типографики, веб-форм, кнопок, меток, блоков навигации и прочих компонентов веб-интерфейса, включая JavaScript-расширения',h1:'Фреймворк Bootstrap', img:'boot.png'},
          {id:9,text:'Онлайн-сервис для разработки интерфейсов и прототипирования с возможностью организации совместной работы в режиме реального времени. Сервис доступен по подписке, предусмотрен бесплатный тарифный план для одного пользователя. Имеются офлайн-версии для Windows, macOS.',h1:'Графический редактор Figma', img:'figma.png'},
          {id:10,text:'Многофункциональный графический редактор, разрабатываемый и распространяемый компанией Adobe Systems. В основном работает с растровыми изображениями, однако имеет некоторые векторные инструменты.',h1:'Adobe Photoshop', img:'PhotoShop.png'},
          {id:11,text:'метаязык на основе CSS, предназначенный для увеличения уровня абстракции CSS-кода и упрощения файлов каскадных таблиц стилей.',h1:'SCSS', img:'scss.png'},
          {id:12,text:'Это фреймворк, позволяющий создавать веб приложения на языке PHP, используя архитектурный паттерн MVC. Yii2 является второй версией фреймворка Yii. ',h1:'PHP-фреймворк Yii2', img:'yii2.png'},
        ],
        isAction: true,
        BanText:[
          "Привет!"," Меня зовут Андрей и я Full-stack разработчик",
          "Сделаю Ваши сайты гормоничными, Современными и качственными!)"
        ],
    //'Услуги' ='#Services'
        listMenuText:['Главная','Кто я','Навыки'],
        listMenuUrl:['#banner','#bubbles','#Skills'],
        UrlTg:'https://t.me/Peak_Andre',
        }
    },
    components:
    {
      Header,
      Banner,
      aboutMe,
      Mycarousel,
      Footer,
    }
  
  }
</script>
<style>

</style>

