<template>
<div class="v-popUp" id="v-popUp" >
   <div class="v-popUp__Header">
   
        <h3 class="lh-lg fst-italic fs-4 text-uppercase">{{H1PopUp}}</h3>
         
        <b-button  @click="ClickClosePopUp" class="close" pill variant="outline-secondary"><div class="textclose">&times;</div></b-button>
   </div>
   <div class="v-popUp__Text">
       <slot></slot>
    </div>
   
</div>
</template>
<script>
export default {
    name:'popUp',
    data() {
        return{

        }
    },
    methods:
    {
        ClickClosePopUp()
        {
            this.$emit('ClosePopUp')
        }
    },
    props:['H1PopUp'],
            
}
</script>
<style scoped>
.v-popUp
{
    padding: 16px;
    
    position: fixed;
    z-index: 4;
    
    max-height: 600px;

    top: 50%;
    overflow-y: scroll;
    left: 50%;
    transform: translate(-50%, -50%);
   color: black;
    width: 90%;
    max-width: 600px;
    border-radius:5px;box-shadow:0 0 15px 5px #ccc;
    background-color:#ebebeb;
    border-left:5px solid #6c6c6c;
}
.v-popUp__Header{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom:2em ;
}
.v-popUp__Text
{
 
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.close{ width: 35px; height: 35px; position: absolute; right: 0;
    color:#6c6c6c;}
.textclose{ font-size:15px; margin: auto;}

</style>