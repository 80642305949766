<template>
<div id="Skills" class="wrapper">
    
    <popUp @ClosePopUp="CloseInfoPopUp" :H1PopUp="listImg[imgid]['h1']" v-if="isInfoPopUpv">
        <div class="lh-lg fst-italic fs-5">{{listImg[imgid]['text']}}</div>
    </popUp>


   <b-container fluid="lg" class="py-5 text-dark"  >
       <h1 class="text-center mb-5">Мой багаж знаний </h1>
    <VueSlickCarousel  v-bind="settings">
        <div @click="ShowPopUpInto(item.id)" class="w-75 cursorPointer" :id="item.id" v-for="item in listImg" :key="item.id" :itemData="item">
            <b-img fluid :src="'img/Skills/'+item.img"/>
        </div>
 
        </VueSlickCarousel>
    </b-container>
</div>
</template>
<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'

  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import popUp from "./popup/v-popUp.vue"
export default {
    name:'Skills',
    data() {
      return {
        imgid:0,
        isInfoPopUpv:false,
        settings: {
                arrows :true,
              
                infinite: true,
                speed: 1000,
                slidesToShow: 6,
                slidesToScroll: 1,
                swipeToSlide: true,
                autoplay: true,
             
                responsive: [
                    {
                    breakpoint: 1000,
                    settings: {
                        arrows :false,
                      
                    }
                    },
                    {
                    breakpoint: 600,
                    settings: {
                        arrows :false,
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                    },
                
                    
                   
                ],
                autoplaySpeed: 2000,
                cssEase: "linear"
        },
      }
    },
    methods:
    {
        ShowPopUpInto(idI)
        {
            this.imgid=idI
            this.isInfoPopUpv= true
        },
        CloseInfoPopUp()
        {
            this.isInfoPopUpv= false
        }
    },
    components:
    {
        VueSlickCarousel,
        popUp
    },
    props:{
        listImg:{
            type:Array,
            default: ()=>[],

        }
    }
}
</script>
<style>
/*! CSS Used from: Embedded */
*,*::before,*::after{box-sizing:border-box;}
::-moz-focus-inner{padding:0;border-style:none;}
/*! CSS Used from: Embedded */
.slick-prev{font-size:0;line-height:0;position:absolute;top:50%;display:block;width:40px;height:40px;padding:0;transform:translate(0, -50%);cursor:pointer;color:transparent;border:none;outline:none;background:transparent;}
.slick-prev:hover,.slick-prev:focus{color:transparent;outline:none;background:transparent;}
.slick-prev:hover:before,.slick-next:hover:before{opacity:1; color: white; transition: 0.7s;}
.slick-prev:before,.slick-next:before{font-family:'slick';font-size:40px;line-height:1;opacity:0.75;color:rgb(100, 100, 100);-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.slick-prev{left:-50px;}

.slick-prev:before{content:'←';}
#Skills
{
    background: #a3a3a3;
}
.cursorPointer
{
    cursor: pointer;
    
    margin-top: 10px;
}
.cursorPointer:hover
{
animation: curAnimate 0.3s forwards;

}
@keyframes curAnimate {
  0% {
   margin-top: 10px;
  }
  100% {
    margin-top: 0;
    
  }
}
</style>